import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Durango&reg;</h2>
          <p>For over 50 years, Durango&reg; has been crafting the world's best western boots with the latest in comfort, style, and technology.</p>
          <p>Lightweight, tough, and unbelievably comfortable, Durango&reg; Boots are designed for your western life. Whether you're riding and roping; on the job, or on the town, Durango&reg; Boots are made for what you do.</p>
          <p>This is Durango&reg; Country.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-body/durango/durango-crew-right.jpg"
            className={brandBodyStyles.halfImage}
            alt="Durango Family"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/durango/durango-square-01.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/durango/durango-square-02.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/durango/durango-square-04.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/durango/durango-square-03.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Official Boot of the NFR&trade;</h4>
            <p>Durango&reg; remains committed to supporting the rodeo industry and athletes, not only through events, but through our robust research and development to create and build boots that support athletes and fans alike. We are proud to be named the Official Boot of the NFR and National Sponsor of ProRodeo&reg;.</p>
            <h4>Team Durango&reg;</h4>
            <p>Durango&reg; has signed fifteen pro-rodeo athletes to create the first ever <a href="https://www.durangoboots.com/team/" title="Team Durango">Team Durango&reg;</a> ambassador program. The talented 2022 roster includes Luke Brown, Paul Eaves, Erich Rogers, Lisa Lockhart, Jordon Briggs, Stevi Hillman, Kelsie Domer, Ty Erickson, Jacob Talley, Orin Larsen, Shane Hanchey, Caleb Smidt, Riley Webb, Zeke Thurston and Garrett Yerigan.</p>
            <h4>Give Cancer the Boot</h4>
            <p>Durango&reg; continues its ongoing support of the <a href="https://cancer.osu.edu/locations/stefanie-spielman-comprehensive-breast-center" title="Stefanie Spielman Fund for Breast Cancer Research at OSU">Stefanie Spielman Fund</a> for Breast Cancer Research at The Ohio State University Comprehensive Cancer Center - Arthur G. James Cancer Hospital and Richard J. Solove Research Institute (OSUCCC - James).<br/>
            To date, Durango&reg; has donated over $269,000 to breast cancer research, with more than $84,000 allocated to the Spielman Fund.</p>
            <h4>Durango&reg; Rebel Pro&trade;</h4>
            <p><a href="https://www.durangoboots.com/mens-rebel-pro/" title="Durango Rebel Pro">Durango&reg; Rebel Pro&trade;</a> boots are designed for performance-driven individuals. With a 7-layer comfort system and an oil and slip-resistant outsole, these boots offer the comfort and style for the saddle and beyond.</p>
            <h4>Durango&reg; Maverick XP&trade;</h4>
            <p>The <a href="https://www.durangoboots.com/durango/mens/by-collection/maverick-xp/" title="Durango Maverick XP">Durango&reg; Maverick XP&trade;</a> collection offers premium western work boots with state of the art technology that offers all day comfort and support for dynamic work-driven individuals.</p>
  
          </div>
      </div>
      <div className={brandBodyStyles.strategy}>
        <h4>Durango Boots Strategy</h4>
        <p>Durango&reg; Boots are industry leading boots built on traditional silhouettes that are paired with the latest innovations in comfort technology that hold up to whatever you put them through.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/join-our-family-of-authorized-dealers/" title="Become a Dealer" className="CTAblack">
            Become a Dealer
          </Link>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody

// extracted by mini-css-extract-plugin
export var about = "BrandBody-module--about--f476f";
export var four = "BrandBody-module--four--f732d";
export var georgiaText = "BrandBody-module--georgiaText--8972d";
export var halfImage = "BrandBody-module--halfImage--2b02f";
export var highlightSection = "BrandBody-module--highlightSection--3a733";
export var highlights = "BrandBody-module--highlights--7d8e2";
export var imageGrid = "BrandBody-module--imageGrid--121cd";
export var info = "BrandBody-module--info--3eb64";
export var michelin = "BrandBody-module--michelin--d5a5b";
export var one = "BrandBody-module--one--3e487";
export var rightImage = "BrandBody-module--rightImage--c8e8f";
export var strategy = "BrandBody-module--strategy--d7e56";
export var three = "BrandBody-module--three--2a6e5";
export var two = "BrandBody-module--two--4880e";